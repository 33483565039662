import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import momentPropTypes from 'react-moment-proptypes'

import get from 'lodash/get'
import LazyLoad from 'react-lazyload'

import { useBaseTheme } from 'Hooks'

import { _ } from 'Services/I18n'
import { getFormattedNextAvailableDate } from 'Services/Utils/service'
import { PUBLIC_PATHS } from 'Constants/paths'
import { WISHLIST_TYPE } from 'Constants/ids'

import { Flex } from 'Components/UI'

import RatingStars from '../RatingStars'
import Categories from '../DefaultCategories/Categories'
import ServiceAvailability from '../ServiceAvailability'
import ServiceDetailsModal from '../ServiceDetailsModal'
import BookingWidgetModal from '../BookingWidgetModal'
import WishlistToggle from '../WishlistToggle'

import { Description, Labels, Location, Price, Title } from './Components'

import {
  Container,
  ContentHolder,
  ImageHolder,
  Overlay,
  ArrowIcon,
  Show,
  MainContentHolder,
  ImageBg,
  Content,
  ContentHolderWrapper,
  HeaderContentHolder,
  BottomContentHolder,
} from './styles'

const ServiceCard = ({
  availabilityDate,
  compact,
  innerRef,
  scrollContainerClass,
  servicesAvailability,
  servicesNextAvailableDate,
  service: { business, categories, service },
  landscape,
  withAvailability,
  withDescription,
  withShowMap,
  onShowOnMap,
  ...rest
}) => {
  const {
    breakpoint,
    isPortableDevice,
    isVoucherRequired,
    showAvailability,
    showReviews,
  } = useBaseTheme()

  const [showDetailsModal, setShowDetailsModal] = useState()
  const [bookingWidgetUrl, setBookingWidgetUrl] = useState()

  const history = useHistory()

  const appended = get(service, 'appended', false)
  const locations = get(service, 'locations', [])
  const isCustomBookingUrl = get(service, 'is_custom_booking_url', false)
  const reviews = get(business, 'reviews')
  const isCompact = compact || isPortableDevice
  const isMerchantPage = withDescription
  const description = get(service, 'description', '')
    .replaceAll('<p>', '')
    .replaceAll('<br />', ' ')
    .replaceAll('</p>', '')
    .replaceAll('<ul>', '')
    .replaceAll('</ul>', '')
    .replaceAll('<b>', '')
    .replaceAll('</b>', '')

  const handleShowWidgetClick = showAvailabilityWidget => {
    const bookingWidgetDateParam =
      availabilityDate && !isCustomBookingUrl
        ? `&date=${availabilityDate.format('YYYY-MM-DD')}`
        : ''

    const availabilityWidgetParam =
      isVoucherRequired && showAvailabilityWidget ? '&book_now=no' : ''

    const url = `${get(
      service,
      'booking_widget_url',
    )}${bookingWidgetDateParam}${availabilityWidgetParam}`

    if (breakpoint === 'mobile' || isCustomBookingUrl) {
      const newWindow = window.open()
      newWindow.opener = null
      newWindow.location = url

      return
    }

    setShowDetailsModal(false)
    setBookingWidgetUrl(url)
  }

  const handleOnCardClick = useCallback(() => {
    if (isMerchantPage) {
      setShowDetailsModal(true)
      return
    }

    history.push(
      PUBLIC_PATHS.SERVICE({
        id: get(service, 'token'),
        slug: get(service, 'slug'),
      }),
      {
        ...history.location.state,
        prevPath: history.location.pathname,
      },
    )
  })

  const handleShowOnMap = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      onShowOnMap({ service })
    },
    [service],
  )

  const renderPrice = (flexDirection = 'column') => (
    <Price
      flexDirection={
        landscape ? 'row' : ['row', 'row', isCompact ? 'row' : flexDirection]
      }
      service={service}
      small={landscape}
    />
  )

  const renderRatings = () =>
    reviews?.total >= 0 && (
      <RatingStars
        businessName={get(business, 'name')}
        color="#52525B"
        display
        reviews={reviews}
        size={landscape || !isCompact ? 12 : 14}
      />
    )

  const renderAvailability = () => {
    let availability = ''

    if (availabilityDate) {
      availability = get(servicesAvailability, get(service, 'id'))
    } else {
      // No date selected, show next available date
      availability = getFormattedNextAvailableDate(
        get(servicesNextAvailableDate, get(service, 'id')),
        service.start_date,
        service.end_date,
      )
    }

    return (
      <ServiceAvailability
        availability={availability}
        color="#52525B"
        flexDirection="row"
        isCustomBookingUrl={isCustomBookingUrl}
        onShowAvailability={() => handleShowWidgetClick(true)}
      />
    )
  }

  // Both list and grid (compact) layout
  const renderContent = () => (
    <ContentHolder
      flexDirection={['column', 'column', isCompact ? 'column' : 'row']}
    >
      <ImageHolder width={['100%', '100%', isCompact ? '100%' : '227px']}>
        <LazyLoad
          height="100%"
          offset={100}
          once
          scrollContainer={scrollContainerClass}
          style={{ height: '100%' }}
        >
          <ImageBg src={get(service, 'image')} />
        </LazyLoad>
        {withShowMap && (
          <Overlay onClick={handleShowOnMap}>
            <Show>{_('common.showOnMap')}</Show>
            <ArrowIcon />
          </Overlay>
        )}
        <WishlistToggle
          token={service.token}
          wishlistType={WISHLIST_TYPE.SERVICE}
        />
      </ImageHolder>

      <MainContentHolder gap="10px" pl={[0, 0, 0, isCompact ? 0 : 12]}>
        <HeaderContentHolder
          flexDirection={['column', 'column', isCompact ? 'column' : 'row']}
          gap="12px"
        >
          <Flex flexDirection="column" pb={0}>
            <Title
              businessName={isMerchantPage ? '' : get(business, 'name')}
              isCompact={isCompact}
              serviceName={get(service, 'name')}
            />
            {isMerchantPage && <Labels mt="6px" service={service} />}
          </Flex>

          {!isMerchantPage && (!isCompact ? renderPrice() : renderRatings())}

          {isMerchantPage && renderPrice()}
        </HeaderContentHolder>

        <Content>
          {!isMerchantPage && (
            <>
              {!isCompact ? (
                <>
                  <Description
                    description={description}
                    showExpand={false}
                    truncateLines={showAvailability || showReviews ? 2 : 4}
                  />
                  <Categories
                    categories={categories}
                    color="#52525B"
                    limit={2}
                    mt={3}
                    subtle
                  />
                </>
              ) : (
                <>
                  <Location
                    locations={locations}
                    online={appended}
                    small={isCompact}
                  />
                  <Labels service={service} />
                </>
              )}
            </>
          )}

          {isMerchantPage && (
            <Description
              description={get(service, 'description', '').replaceAll(
                '<p><br /></p>',
                '',
              )}
              showExpand={false}
              truncateLines={compact ? 5 : 3}
            />
          )}

          <BottomContentHolder
            alignItems={[
              'flex-start',
              'flex-start',
              isCompact ? 'flex-start' : 'flex-end',
            ]}
            flexDirection={[
              'column',
              'column',
              isCompact ? 'column-reverse' : 'row',
            ]}
          >
            {withAvailability && renderAvailability()}

            {!isMerchantPage && (!isCompact ? renderRatings() : renderPrice())}
          </BottomContentHolder>
        </Content>
      </MainContentHolder>
    </ContentHolder>
  )

  // Mobile map view card layout
  // Lazy loading the image isn't needed here as the slider handles it
  const renderLandscapeContent = () => (
    <ContentHolder flexDirection="row" landscape={1}>
      <ImageHolder landscape={1} width="125px">
        <ImageBg landscape={1} src={get(service, 'image')} />
        {withShowMap && (
          <Overlay onClick={handleShowOnMap}>
            <Show>{_('common.showOnMap')}</Show>
            <ArrowIcon />
          </Overlay>
        )}
        <WishlistToggle
          token={service.token}
          wishlistType={WISHLIST_TYPE.SERVICE}
        />
      </ImageHolder>

      <MainContentHolder gap="2px" pl="0">
        <HeaderContentHolder flexDirection="column" gap="4px" pt="6px">
          <Flex flexDirection="column" pb="2px">
            <Title
              businessName={isMerchantPage ? '' : get(business, 'name')}
              serviceName={get(service, 'name')}
              small
            />
          </Flex>

          {renderRatings()}
          {renderPrice()}
        </HeaderContentHolder>

        <Content>
          <BottomContentHolder alignItems="flex-start" flexDirection="column">
            {renderAvailability()}
          </BottomContentHolder>
        </Content>
      </MainContentHolder>
    </ContentHolder>
  )

  return (
    <Container ref={innerRef}>
      <ContentHolderWrapper
        landscape={landscape ? 1 : 0}
        roundedborder={0}
        {...rest}
        onClick={handleOnCardClick}
      >
        {landscape ? renderLandscapeContent() : renderContent()}
      </ContentHolderWrapper>

      {showDetailsModal && (
        <ServiceDetailsModal
          business={business}
          categories={categories}
          locations={locations}
          reviews={reviews}
          service={service}
          onBook={() => handleShowWidgetClick(false)}
          onClose={() => setShowDetailsModal(false)}
        />
      )}

      {bookingWidgetUrl && (
        <BookingWidgetModal
          url={bookingWidgetUrl}
          onClose={() => setBookingWidgetUrl(false)}
        />
      )}
    </Container>
  )
}

ServiceCard.defaultProps = {
  availabilityDate: null,
  compact: false,
  innerRef: null,
  landscape: false,
  scrollContainerClass: '',
  service: null,
  servicesAvailability: null,
  servicesNextAvailableDate: null,
  withAvailability: true,
  withDescription: false,
  withShowMap: false,
  onShowOnMap: () => {},
}

ServiceCard.propTypes = {
  availabilityDate: momentPropTypes.momentObj,
  compact: PropTypes.bool,
  innerRef: PropTypes.object,
  landscape: PropTypes.bool,
  scrollContainerClass: PropTypes.string,
  service: PropTypes.object,
  servicesAvailability: PropTypes.object,
  servicesNextAvailableDate: PropTypes.object,
  withAvailability: PropTypes.bool,
  withDescription: PropTypes.bool,
  withShowMap: PropTypes.bool,
  onShowOnMap: PropTypes.func,
}

export default ServiceCard
